import React, { useState, useEffect } from "react";

import {
  Create,
  CreateProps,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  Filter,
  FormTab,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
} from "react-admin";

import parsePhoneNumber, { AsYouType } from "libphonenumber-js";

// Return in a readable format
const formatPhone = (v: string | undefined) => {
  if (!v) return v;
  return new AsYouType("SE").input(ensureFormatting(v) ?? v);
};

// Does the following two things:
// 1. Make sure we have a leading coutry code
// 2. Remove any trunk prefix, E.g. +46 070-123 45 67 ... becomes +46 07-123 45 67
const ensureFormatting = (v: string) =>
  parsePhoneNumber(v, "SE")?.formatInternational();

// Remove any formatting information before sending to the backend
const parsePhone = (v: string | undefined) => {
  const formatted = formatPhone(v);
  if (!formatted) return formatted;
  return formatted.replace(/[^+\d]/g, "");
};

const validatePhone = (v: string | undefined) => {
  if (!v) return undefined;
  const parseResult = parsePhoneNumber(v, "SE");
  if (!parseResult?.isValid()) return "Invalid phone number";
};

export const UserFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList: React.FC = (props) => (
  <List {...props} filters={<UserFilter />} exporter={false}>
    <Datagrid>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <FunctionField
        label="Phone number"
        render={(row: any) => formatPhone(row.phoneNumber)}
      />
      <DateField source="phoneNumberVerifiedAt" />
      <ReferenceField
        label="Betalportal"
        source="franchiseId"
        reference="Franchise"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updatedAt" />
      <DateField source="insertedAt" />
      <EditButton basePath="/User" />
    </Datagrid>
  </List>
);

interface UserEditProps extends EditProps {
  isAdmin: boolean;
}

export const UserEdit: React.FC<UserEditProps> = ({ isAdmin, ...props }) => {
  return (
    <Edit title="Update User" {...props}>
      <TabbedForm>
        <FormTab label="User Details">
          <TextInput source="id" disabled />
          <TextInput source="auth0Id" label="Auth0 ID" />

          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput
            source="email"
            parse={(v: string | undefined) => {
              if (!v) return v;
              return v.toLowerCase();
            }}
          />
          <TextInput
            source="phoneNumber"
            format={formatPhone}
            parse={parsePhone}
            validate={validatePhone}
          />
          <TextField source="phoneNumberVerifiedAt" />

          <ReferenceInput
            label="Betalportal"
            source="franchiseId"
            reference="Franchise"
            allowEmpty
            disabled={!isAdmin}
          >
            <SelectInput />
          </ReferenceInput>

          <TextInput source="password" type="password" label="New Password" />

          <TextField source="updatedAt" />
          <TextField source="insertedAt" />
        </FormTab>

        <FormTab label="Panels">
          <ReferenceManyField
            label="Member of panels"
            reference="UserPanel"
            target="user"
          >
            <Datagrid>
              <ReferenceField label="Panel" source="panelId" reference="Panel">
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

interface UserCreateProps extends CreateProps {
  isAdmin: boolean;
}

export const UserCreate: React.FC<UserCreateProps> = ({
  isAdmin,
  ...props
}) => {
  // Set up a data provider to query for franchises
  const dataProvider = useDataProvider();

  // Find the default franchise, which is empty string to start with
  const [defaultFranchise, setDefaultFranchise] = useState("");

  // Query for franchises and find the uuid for Svenska Alarm
  useEffect(() => {
    dataProvider
      .getList<{ id: string; name: string }>("Franchise", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        // Loop over franchises
        for (const franchise of data) {
          // Set uuid for Svenska Alarm as default if available
          if (franchise.name === "SAG") {
            setDefaultFranchise(franchise.id);
            return;
          }
        }
      });
  }, [dataProvider]);

  return (
    <Create title="Create a User" {...props}>
      <SimpleForm>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput
          source="email"
          parse={(v: string | undefined) => {
            if (!v) return v;
            return v.toLowerCase();
          }}
        />
        <TextInput
          source="phoneNumber"
          format={formatPhone}
          parse={parsePhone}
          validate={validatePhone}
        />

        <ReferenceInput
          label="Betalportal"
          source="franchiseId"
          reference="Franchise"
          allowEmpty
          initialValue={defaultFranchise}
          disabled={!isAdmin}
        >
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
