import React from "react";

import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";

import YesNoField from "./fields/YesNoField";

const USER_ROLE_OPTIONS = [
  { id: "ADMIN", value: "Admin" },
  { id: "USER", value: "User" },
];

export const UserPanelFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserPanelList: React.FC = (props) => (
  <List {...props} filters={<UserPanelFilter />} exporter={false}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="User">
        <TextField source="email" />
      </ReferenceField>

      <ReferenceField label="Panel" source="panelId" reference="Panel">
        <TextField source="name" />
      </ReferenceField>

      <ChipField label="User role" source="userRole" />
      <YesNoField label="Dispatch guard" source="allowGuardDispatch" />
      <YesNoField label="Photo on demand" source="allowPhotoOnDemand" />

      <DateField source="updatedAt" />
      <DateField source="insertedAt" />

      <EditButton basePath="/UserPanel" />
    </Datagrid>
  </List>
);

export const UserPanelEdit: React.FC = (props) => (
  <Edit title="Update UserPanel" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <TextInput source="id" disabled />

        <ReferenceInput label="User Email" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput label="Panel Name" source="panelId" reference="Panel">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <SelectInput
          label="User role"
          source="userRole"
          optionText="value"
          choices={USER_ROLE_OPTIONS}
        />

        <BooleanInput label="Dispatch guard" source="allowGuardDispatch" />
        <BooleanInput label="Photo on demand" source="allowPhotoOnDemand" />

        <TextField source="updatedAt" />
        <TextField source="insertedAt" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const UserPanelCreate: React.FC = (props) => (
  <Create title="Create a UserPanel" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <ReferenceInput label="User" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput label="Panel" source="panelId" reference="Panel">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <SelectInput
          label="User role"
          source="userRole"
          optionText="value"
          initialValue="ADMIN"
          choices={USER_ROLE_OPTIONS}
        />

        <BooleanInput
          label="Dispatch guard"
          initialValue={true}
          source="allowGuardDispatch"
        />
        <BooleanInput
          label="Photo on demand"
          initialValue={true}
          source="allowPhotoOnDemand"
        />
      </FormTab>
    </TabbedForm>
  </Create>
);
