import React, { useState, useEffect, useMemo } from "react";

import {
  BooleanInput,
  ChipField,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  Filter,
  FormTab,
  FunctionField,
  List,
  NumberInput,
  Record,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  required,
  useDataProvider,
} from "react-admin";

import YesNoField from "./fields/YesNoField";

import parsePhoneNumber from "libphonenumber-js";

const PROFILE_TYPE_ALL_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH", value: "Guard Direct" },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

const PROFILE_TYPE_TECHNICIAN_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

const PROFILE_TYPE_TECHNICIAN_EDIT_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH", value: "Guard Direct", disabled: true },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

// Return in a readable format
const formatPhone = (v: string | undefined) => {
  if (!v) return v;
  return (ensureFormatting(v) ?? v).replace(/[^+\d]/g, "");
};

// Does the following two things:
// 1. Make sure we have a leading coutry code
// 2. Remove any trunk prefix, E.g. +46 070-123 45 67 ... becomes +46 07-123 45 67
const ensureFormatting = (v: string) =>
  parsePhoneNumber(v, "SE")?.formatInternational();

// Remove any formatting information before sending to the backend
const parsePhone = (v: string | undefined) => {
  const formatted = formatPhone(v);
  if (!formatted) return formatted;
  return formatted.replace(/[^+\d]/g, "");
};

// Helper function to help user input a correctly formed ajax hub id
const parseAjaxHubId = (v: string | undefined) =>
  v
    // Ajax hub ids are max 8 characters long
    ?.slice(0, 8)
    // Characters A-F are case insensitive
    .toUpperCase()
    // Only allow A-F and 0-9
    .replace(/[^A-F0-9]/g, "");

// Helper function to validate the ajax hub
const validateAjaxHubId = (v: string | undefined) => {
  // An ajax hub id is always 8 characters long
  if (v && v.length !== 8) {
    return "must be 8 characters long";
  }
  // The validation functions returns undefined if valid
  return undefined;
};
/**
 * Function to translat profile type ids to readable values
 *
 * Returns empty string if profile type could not be matched
 */
export const getProfileTypeName = (
  record: Record | undefined,
  source: string | undefined
) => {
  // Check the empty case firssst
  if (!record || !source) return "";

  // Get the profile type id
  const profileType = record[source];

  // Try to find it among above options
  for (const profileTypeOption of PROFILE_TYPE_ALL_OPTIONS) {
    if (profileTypeOption.id === profileType) {
      // Return if found
      return profileTypeOption.value;
    }
  }

  // Otherwise, just return the id
  return profileType || "";
};

export const panelStatusOptions = [
  { id: "DISARM", value: "Disarmed" },
  { id: "ARM_HOME", value: "Arm Home" },
  { id: "ARM_AWAY", value: "Arm Away" },
];

export const panelOperatingModeOptions = [
  { id: "INACTIVE", value: "Inactive" },
  { id: "SERVICE", value: "Service" },
  { id: "ACTIVE", value: "Active" },
];

export const PanelFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PanelList: React.FC = (props) => (
  <List {...props} filters={<PanelFilter />} exporter={false}>
    <Datagrid>
      <TextField source="name" label="Panel name" />

      <FunctionField
        source="profileType"
        label="Response"
        render={getProfileTypeName}
      />

      <TextField source="objectId" label="ID (Visonic)" />
      <TextField source="ajaxHubId" label="ID (Ajax)" />
      <FunctionField
        label="GSM Number"
        render={(row: any) => formatPhone(row.gsmNumber)}
      />
      <ChipField source="status" />

      <YesNoField
        source="allowCalmManualDispatch"
        label="Allow Manual Dispatch"
      />

      <YesNoField
        source="autoDispatchInstantPanic"
        label="Automatic Dispatch for Panic and Emergency"
      />

      <YesNoField source="showYaleAccessLink" label="Show Link to Yale App" />

      <YesNoField source="homeModeEnabled" label="Home Mode Enabled" />

      <YesNoField
        source="zonesEnabled"
        label="Partitions for Ajax groups Enabled (does not work for Visonic)"
      />

      <DateField source="updatedAt" />
      <DateField source="insertedAt" />

      <EditButton basePath="/Panel" />
    </Datagrid>
  </List>
);

interface PanelEditProps extends EditProps {
  isAdmin: boolean;
}

export const PanelEdit: React.FC<PanelEditProps> = ({ isAdmin, ...props }) => {
  // Exclude legacy options for non-admin users
  const inputChoices = useMemo(() => {
    if (isAdmin) return PROFILE_TYPE_ALL_OPTIONS;
    return PROFILE_TYPE_TECHNICIAN_EDIT_OPTIONS;
  }, [isAdmin]);

  // Validate profile type
  const validateProfileType = useMemo(
    () => (value: string) => {
      if (isAdmin) return undefined;
      if (value === "AUTO_DISPATCH") {
        return "This option has been disabled";
      }
    },
    [isAdmin]
  );

  return (
    <Edit title="Update Panel" {...props}>
      <TabbedForm>
        <FormTab label="Panel Details">
          <TextInput source="id" disabled />

          <TextInput source="name" label="Panel name" />

          <SelectInput
            source="profileType"
            label="Response"
            optionText="value"
            choices={inputChoices}
            validate={validateProfileType}
          />

          <TextInput source="objectId" label="ID (Visonic)" />
          <TextInput
            source="ajaxHubId"
            label="ID (Ajax)"
            parse={parseAjaxHubId}
            validate={validateAjaxHubId}
          />

          <SelectInput
            source="operatingMode"
            optionText="value"
            choices={panelOperatingModeOptions}
          />

          <SelectInput
            source="status"
            optionText="value"
            choices={panelStatusOptions}
          />

          <TextInput
            source="gsmNumber"
            label="GSM Number"
            format={formatPhone}
            parse={parsePhone}
          />

          <TextInput source="address" label="Street address" />
          <TextInput source="postcode" />
          <TextInput source="city" />

          <ReferenceInput
            label="Response Service"
            source="responseServiceId"
            reference="ResponseService"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>

          <BooleanInput
            source="allowCalmManualDispatch"
            label="Activate Guard Now button for the app"
          />
          <BooleanInput
            source="autoDispatchInstantPanic"
            label="Activate Automatic Dispatch for Panic and Emergency"
          />
          <BooleanInput
            source="showYaleAccessLink"
            label="Show Link to Yale App"
          />

          <TextInput
            source="tempIndoorSensorId"
            label="Indoor Temp Sensor"
            helperText="Ajax Device-ID (8 characters)"
          />

          <NumberInput
            source="tempAlarmIndoorTempMin"
            label="Temp Alarm - Indoor Min Value"
            helperText="Ajax only - the alarm event will trigger when crossing the temperature."
          />

          <NumberInput
            source="tempAlarmIndoorTempMax"
            label="Temp Alarm - Indoor Max Value"
            helperText="Ajax only - the alarm event will trigger when crossing the temperature."
          />

          <NumberInput
            source="tempAlarmOutdoorTempMin"
            label="Temp Alarm - Outdoor Min Value"
            helperText="The alarm event will trigger when crossing the temperature."
          />

          <NumberInput
            source="tempAlarmOutdoorTempMax"
            label="Temp Alarm - Outdoor Max Value"
            helperText="The alarm event will trigger when crossing the temperature."
          />

          <BooleanInput source="homeModeEnabled" label="Home Mode Enabled" />

          <BooleanInput
            source="zonesEnabled"
            label="Partitions for Ajax groups Enabled (does not work for Visonic)"
          />

          <BooleanInput
            source="photoOnDemandEnabled"
            label="Photo on demand for Ajax Enabled (does not work for Visonic)"
          />
        </FormTab>

        <FormTab label="Members">
          <ReferenceManyField
            label="Members on panel"
            reference="UserPanel"
            target="panel"
          >
            <Datagrid>
              <ReferenceField label="User" source="userId" reference="User">
                <TextField source="email" />
              </ReferenceField>

              <ChipField label="User role" source="userRole" />

              <YesNoField label="Dispatch guard" source="allowGuardDispatch" />
              <YesNoField label="Photo on demand" source="allowPhotoOnDemand" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

interface PanelCreateProps extends CreateProps {
  isAdmin: boolean;
}

export const PanelCreate: React.FC<PanelCreateProps> = ({
  isAdmin,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const [defaultResponseService, setDefaultResponseService] = useState("");

  useEffect(() => {
    dataProvider
      .getList<{ id: string; name: string }>("ResponseService", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        for (const responseService of data) {
          if (responseService.name === "Lövestad - Securitas") {
            setDefaultResponseService(responseService.id);
            return;
          }
        }
      });
  }, [dataProvider]);

  // Exclude legacy options for non-admin users
  const inputChoices = useMemo(() => {
    if (isAdmin) return PROFILE_TYPE_ALL_OPTIONS;
    return PROFILE_TYPE_TECHNICIAN_OPTIONS;
  }, [isAdmin]);

  return (
    <Create title="Create a Panel" {...props}>
      <SimpleForm>
        <TextInput source="name" label="Panel name" />

        <SelectInput
          source="profileType"
          label="Response"
          optionText="value"
          choices={inputChoices}
        />

        <TextInput source="objectId" label="ID (Visonic)" />
        <TextInput
          source="ajaxHubId"
          label="ID (Ajax)"
          parse={parseAjaxHubId}
          validate={validateAjaxHubId}
        />

        <SelectInput
          source="operatingMode"
          optionText="value"
          choices={panelOperatingModeOptions}
        />

        <SelectInput
          source="status"
          optionText="value"
          choices={panelStatusOptions}
        />

        <TextInput
          source="gsmNumber"
          label="GSM Number"
          format={formatPhone}
          parse={parsePhone}
        />
        <TextInput source="address" label="Street address" />
        <TextInput source="postcode" />
        <TextInput source="city" />

        <BooleanInput
          source="allowCalmManualDispatch"
          label="Activate Guard Now button for the app"
          defaultValue={true}
        />

        <BooleanInput
          source="photoOnDemandEnabled"
          label="Photo on demand for Ajax Enabled (does not work for Visonic)"
          defaultValue={true}
        />

        <ReferenceInput
          label="Response Service"
          source="responseServiceId"
          reference="ResponseService"
          initialValue={defaultResponseService}
        >
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
